
import Vue from "vue";
import PermissionsGroupsList from "@/components/subviews/permissions-groups/permissions-groups-list.vue";
import PermissionsGroupsMaintenance from "@/components/subviews/permissions-groups/permissions-groups-maintenance.vue";
import IPermissionGroup from "@/lib/interfaces/permission-group";

export default Vue.extend({
  name: "PermissionsGroups",

  components: { PermissionsGroupsList, PermissionsGroupsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      permissionGroup: {} as IPermissionGroup,
    };
  },
});
